import { createRouter, createWebHistory } from 'vue-router'
import { useSiteStore } from '@/stores/siteStore';
import { useAuthStore } from '@/stores/authStore';
import { useHotelStore } from '@/stores/hotelStore';
// Cookie
import {CookieService} from '@/service/CookieService.js';
import {SiteService} from '@/service/SiteService.js';
import jwt from 'jsonwebtoken';
import axios from 'axios';

const routes = [
  {
    path: '/',
    component: () => import('@/views/BusinessHome.vue'),
  },
  {
    path: '/home',
    component: () => import('@/views/BusinessHome.vue'),
    name: 'Home'
  },
  {
    path: '/company',
    component: () => import('@/views/BusinessCompany.vue'),
    name: 'Company'
  },
  {
    path: '/insight',
    component: () => import('@/views/BusinessInsight.vue'),
    name: 'Insight'
  },
  {
    path: '/line',
    component: () => import('@/views/BusinessLine.vue'),
    name: 'Line'
  },
  {
    path: '/trip',
    name: 'trip',
    redirect: () => {
      const siteStore = useSiteStore();
      return { name: 'index_locale', params: { locale: siteStore.settings.displayLanguages } };
    }
  },
  {
    path:'/trip/:locale',
    sensitive: true,
    component: () => import('@/views/IndexView.vue'),
    name: 'index_locale',
    prop: true,
  },
  {
    path: '/trip/:locale/travellist',
    component: () => import('@/views/TravelList.vue'),
    name: 'travellist',
  },
  {
    path: '/trip/:locale/traveldetail/:id',
    component: () => import('@/views/TravelDetails.vue'),
    name: 'traveldetail',
    props: true,
  },
  {
    path: '/trip/:locale/Ikenotaira/', // 池之平
    component: () => import('@/views/Project-Ikenotaira.vue'),
    name: 'Ikenotaira'
  },
  {
    path: '/trip/:locale/Zagyosoh/', // 坐漁莊
    component: () => import('@/views/Project-Zagyosoh.vue'),
    name: 'Zagyosoh'
  },
  {
    // path: '/uc',
    path: "/:locale/:pathMatch(.*)*", // 任何不存在的path, 則進入 UnderConstruction.vue
    component: () => import('@/views/UnderConstruction.vue')
  },
  {
    path: '/trip/:locale/privacypolicy',
    component: () => import('@/views/PrivacyPolicy.vue'),
    name: 'PrivacyPolicy'
  },
  {
    path: "/trip/:locale/hotelList",  // keywords搜尋後之飯店清單
    component: () => import("@/views/HotelLists.vue"),
    name: 'HotelLists',
  },
  {
    path: "/trip/:locale/hotelDetail",  // 指定飯店之詳細方案&房型
    component: () => import("@/views/HotelDetail.vue"),
    name: 'HotelDetail',
  },
  {
    path: "/trip/:locale/hotelCheck", // 客人填寫表單頁
    component: () => import("@/views/HotelCheck.vue"),
    name: "hotelCheck",
    meta: { requiresAuth: true }
  },
  {
    path: "/trip/:locale/myAccount", // 我的帳號
    component: () => import("@/components/MyMember/MyAccount.vue"),
    name: "myAccount",
    meta: { requiresAuth: true }
  },
  {
    path: "/trip/:locale/myOrder", // 我的訂單
    component: () => import("@/components/MyMember/MyOrder.vue"),
    name: "myOrder",
    meta: { requiresAuth: true },
    // props: (route) => ({page: route.query.page}) // 用props, 這樣一進MyOrder.vue, URL會吃第一頁, 分頁也會高亮
  },
  {
    path: "/trip/:locale/myReferral", // 我的推薦人
    component: () => import("@/components/MyMember/MyReferral.vue"),
    name: "myReferral",
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const siteStore = useSiteStore();
      if(siteStore.userInfo.IdentityType === '1') {
        next();
      } else {
        // 回上一頁
        router.go(-1);
      }
    }
  },
  {
    path: "/trip/:locale/myFavorite", // 我的最愛
    component: () => import("@/components/MyMember/MyFavorite.vue"),
    name: "myFavorite",
    meta: { requiresAuth: true }
  },
  {
    path: "/trip/:locale/orderCompleted", // 完成訂單(4 Steps)
    component: () => import("@/components/Order/OrderCompleted.vue"),
    name: "OrderCompleted",
  },
  {
    path: "/trip/:locale/orderFailed", // 訂單失敗
    component: () => import("@/components/Order/OrderFailed.vue"),
    name: "OrderFailed",
  },
  {
    path: "/trip/:locale/Order", //「指定」飯店訂單
    component: () => import("@/components/Order/Order.vue"),
    name: "Order",
  },
  {
    path: "/trip/:locale/cashier", // 雲收銀Loading
    component: () => import ("@/components/PlanForm/CashierFormData.vue"),
    name: "Cashier"
  },
  {
    path: '/Auth', // 第三方登入後api回傳的頁面
    name: 'Auth',
    beforeEnter: (to, from, next) => {
      const siteStore = useSiteStore();
      try {
        if(to.query["Status"] == "Failure"){
          next({ path: '/trip' });
        }
        let token = to.query["Token"];
        let refreshToken = to.query["RefreshToken"];
        localStorage.setItem('AccessToken', token);
        localStorage.setItem('RefreshToken', refreshToken);
        let jwtDecode = '';
        if (token !== null || token !== "undefined") {
          jwtDecode = jwt.decode(token);
          if (jwtDecode) {
            siteStore.userInfo = jwtDecode;
          }
        }
        // 發送設備碼及推薦人的推薦碼
        const apiUrl = process.env.VUE_APP_API_URL + '/api/V1/P02/A06/A02/P02A06A02M005';
        let referralCode = CookieService.getItem("ReferralCode") ?? '';
        let equipmentNo = localStorage.getItem("ENO") ?? '';
        const data = {
          "Language": siteStore.settings.displayLanguages,
          "Id": jwtDecode.sid ?? '',
          "Email": jwtDecode.email ?? '',
          "SrcAttr": jwtDecode.Source ?? '',
          "RecommendedBy": referralCode ?? '',
          "EquipmentNo": equipmentNo
        }
        axios.post(apiUrl, data)
          .then(response => {
            //console.log('登入成功發送');
          })
          .catch(error => {
            console.error('發送登入時出錯', error);
            next({ path: '/trip' });
          });
        next({ path: '/trip' });
      } catch (error) {
        next({ path: '/trip' });
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      console.log("moving to top of the page");
      window.scrollTo(0, 0);
    }
  },
  strict: true,
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const siteStore = useSiteStore();
  /* 使用router.beforeEach來設定全局前置守衛, 記錄進入網頁設備的資訊(開始) */

  const apiUrl = process.env.VUE_APP_API_URL + '/api/V1/P02/A06/A02/P02A06A02M004';
  let referralCode = CookieService.getItem("ReferralCode");
  let equipmentNo = localStorage.getItem("ENO") ?? '';
  if (to.query.Code && (referralCode == "" || referralCode == null || referralCode == undefined)) {
    referralCode = to.query.Code;
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    CookieService.setItem("ReferralCode", referralCode, expirationDate,  '/');
  }
  if (equipmentNo == "" || equipmentNo == null || equipmentNo == undefined) {
    equipmentNo = SiteService.createEquipmentInfo();
    localStorage.setItem("ENO", equipmentNo);
  }
  const data = {
    userId : siteStore.userInfo?.sid ?? '',
    equipmentNo : equipmentNo ?? '',
    entryUrl: to.fullPath,
    referralCode: referralCode ?? '',
  };
  axios.post(apiUrl, data)
    .then(response => {
      //console.log('頁面訪問記錄成功發送');
    })
    .catch(error => {
      console.error(error);
      console.error(from);
    });
  /* 使用router.beforeEach來設定全局前置守衛, 記錄進入網頁設備的資訊(結束) */
  // TODO: 其它動作

  /* 取得登入狀態(開始) */
  let token = localStorage.getItem("AccessToken");
  let refreshToken = localStorage.getItem("RefreshToken");
  // 確認是否有登入
  siteStore.userInfo = "";
  if (token && token.length > 0 && token !== "undefined") {
    let jwtDecode = jwt.decode(token);
    // 確認token是否過期，過期則重新則確認真否有refreshToken，有則重新取得AccessToken
    if (jwtDecode.exp < Date.now() / 1000) {
      localStorage.removeItem("AccessToken");
      // 有refreshToken，重新取得AccessToken
      if (refreshToken && refreshToken.length > 0) {
        authStore.refreshToken(refreshToken).then(accessToken => {
          // refreshToken失效, 則刪除LocalStorage，提示重新登入
          if(accessToken == null || accessToken == "undefined"){
            localStorage.removeItem("AccessToken");
            localStorage.removeItem("RefreshToken");
            if (to.meta.requiresAuth) {
              authStore.errorSwal('Error', 'Refreshing token invalid');
              next({ path: '/' });
            }
            return;
          }
          // refreshToken有效，重新設定AccessToken
          localStorage.setItem("AccessToken", accessToken);
          let jwtDecode = jwt.decode(accessToken);
          siteStore.userInfo = jwtDecode;
        }).catch(error => {
          console.error("Error refreshing token:", error);
          if (to.meta.requiresAuth) {
            authStore.errorSwal('Error', 'Refreshing token invalid');
            next({ path: '/' });
          }
          return;
        });
      }
    }
    else {
      // token未過期，直接設定userInfo
      siteStore.userInfo = jwtDecode;
    }
  }
  // 如果token不存在，且有refreshToken，重新取得AccessToken
  else if ((token ==null || token == "undefined") && refreshToken && refreshToken.length > 0) {
    authStore.refreshToken(refreshToken).then(accessToken => {
      // refreshToken失效, 則刪除LocalStorage，提示重新登入
      if(accessToken == null || accessToken == "undefined"){
        localStorage.removeItem("AccessToken");
        localStorage.removeItem("RefreshToken");
        if (to.meta.requiresAuth) {
          authStore.errorSwal('Error', 'efreshing token invalid');
          next({ path: '/' });
        }
        return;
      }
      // refreshToken有效，重新設定AccessToken
      localStorage.setItem("AccessToken", accessToken);
      let jwtDecode = jwt.decode(accessToken);
      siteStore.userInfo = jwtDecode;
    }).catch(error => {
      console.error("Error refreshing token:", error);
      if (to.meta.requiresAuth) {
        authStore.errorSwal('Error', 'efreshing token invalid');
        next({ path: '/' });
      }
      return;
    });
  }
  /* 取得登入狀態(結束) */

  // 繼續路由導航
  next();
});


// 1. 若不是進入「飯店列表」以及「房型方案」, 進階搜尋變數全初始化!
router.afterEach((to, from) => {
  const hotelStore = useHotelStore();
  if(to.name !== 'HotelLists' && to.name !== 'HotelDetail') {
    hotelStore.advancedParams.smoking = null;
    hotelStore.advancedParams.bedType = null;
    hotelStore.advancedParams.facilityArr = [];
    hotelStore.filteredAllHotels.HotelPriceRange = {}
  }
})

export default router